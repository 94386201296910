<template>
  <v-container grid-list-md pa-0 relative>
    <Loader v-if="showloader"></Loader>

    <v-layout row wrap>
      <v-flex xs12 sm12 :refeshKey="refeshKey">
        <v-card outlined class="primary--border">
          <div v-if="galleryImages.length > 0">
            <div class="d-flex" style="justify-content: space-between">
              <div>
                <v-card-title class="title" style="padding-bottom: 0px">
                  Gallery Images
                </v-card-title>

                <p style="padding-left: 16px; font-size: 15px; color: gray">
                  Selected images for the gallery.
                </p>
              </div>
              <add-button
                icon="crop_free"
                class="mr-3 mt-5"
                @action="
                  (previewImages = galleryImages), (slider_preview = true)
                "
                :permission="true"
              >
                Preview
              </add-button>
            </div>
            <!-- {{ delete_pic }} -->
            <v-layout pl-3 pr-3 wrap :refeshKey="refeshKey">
              <v-flex xs3 v-for="(gallery, g) in galleryImages" :key="g">
                <v-card class="mx-auto" max-width="400">
                  <div>
                    <v-img
                      class="white--text align-end"
                      height="200px"
                      :src="gallery.image"
                      @dblclick="selectImage(gallery)"
                    >
                      <div class="opacity-background">
                        <v-card-title
                          class="title"
                          style="
                            padding-bottom: 0px;
                            font-size: 12px !important;
                            padding: 4px 14px;
                            line-height: 20px;
                          "
                        >
                          {{ gallery.title }}
                        </v-card-title>

                        <p
                          style="
                            padding-left: 14px;
                            font-size: 12px;
                            color: #fff;
                            margin-top: -5px;
                          "
                        >
                          {{ gallery.description }}
                        </p>
                      </div>
                    </v-img>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </div>

          <div v-for="(album_img, key) in album_images" :key="key">
            <br />
            <div class="d-flex" style="justify-content: space-between">
              <div>
                <v-card-title
                  class="title"
                  style="padding-bottom: 0px"
                  v-if="album_img.photos.length > 0"
                >
                  {{ album_img.title }}
                </v-card-title>
                <p style="padding-left: 16px; font-size: 15px; color: gray">
                  {{ album_img.description }}
                </p>
              </div>
              <add-button
                icon="crop_free"
                class="mr-3 mt-5"
                @action="
                  (previewImages = album_img.photos), (slider_preview = true)
                "
                :permission="true"
              >
                Preview
              </add-button>
            </div>
            <v-layout pl-3 pr-3 wrap>
              <v-flex xs3 v-for="(p, k) in album_img.photos" :key="k">
                <v-card class="mx-auto" max-width="400">
                  <div :class="p.is_selected === '1' ? 'in-gallery' : ''">
                    <v-img
                      class="white--text align-end"
                      height="200px"
                      :src="p.image"
                      @dblclick="selectImage(p)"
                    ></v-img>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog_open" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Image For Gallery</span>
        </v-card-title>
        <v-card-text class="pa-1">
          <v-form ref="form">
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 class="mb-0">
                  <v-text-field
                    label="Album Title*"
                    autocomplete="off"
                    outlined
                    dense
                    required
                    class="pa-0"
                    v-model="form.title"
                    name="name"
                    :error-messages="form.errors.get('title')"
                    :rules="[(v) => !!v || 'Album Title Field is required']"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="(dialog_open = false), form.reset()"
            >Close</v-btn
          >
          <v-btn
            :disabled="form.title === '' ? true:false"
            color="success"
            text
            @click="validate()"
            v-if="display_save_btn"
            >Save</v-btn
          >
          <!-- <delete-button
            permission="album-delete"
            :outlined="true"
            :message="'Are you sure you want to remove this image from the gallery list'"
            @agree="updatePhoto(0)"
            v-else
          ></delete-button> -->
          <!-- <v-btn
            v-else
            x-small
            outlined
            color="error"
            class="ml-1 mt-1 mb-1"
            @agree="updatePhoto(0)"
          >
            <v-icon x-small>clear</v-icon
            ><strong class="ml-1">Delete</strong>
         </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_pic" persistent max-width="400px">
      <v-card>
        <v-card-title class="title pa-3 red white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Please, wait!
        </v-card-title>
        <v-card-text class="ma-0">
          <!-- <h3>{{message||'Are you sure you want to delete this item?'}}</h3>
                <v-divider></v-divider> -->

          <br />
          <alert-message
            extra-small
            type="error"
            title="Please wait !!"
            message="Are you sure you want to remove this image from the gallery list"
          ></alert-message>
          <!-- :message="'Make sure you are fully aware what you are doing. You can always contact us if you are confused or need any help.'" -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text small @click="delete_pic = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text small @click="updatePhoto(0)">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      @keydown.esc="slider_preview = false"
      v-model="slider_preview"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Slider Preview</span>
          <v-spacer></v-spacer>
          <v-icon
            @click="(previewImages = []), (slider_preview = false)"
            style="color: white"
            >close</v-icon
          >
        </v-card-title>
        <v-card-text class="pa-1">
          <v-carousel>
            <v-carousel-item
              v-for="(item, i) in previewImages"
              :key="i"
              :src="item.image"
            ></v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

import Loader from "../../../../components/Loader.vue";
export default {
  components: { Loader },
  mixins: [Mixins],

  data: () => ({
    refeshKey: 0,
    dialog_open: false,
    display_save_btn: false,
    album_images: "",
    delete_pic: false,
    showloader: false,
    galleryImages: [],
    form: new Form(
      {
        id: "",
        title: "",
        is_selected: "",
      },
      "/api/album-images"
    ),
    slider_preview: false,
    previewImages: [],
  }),
  mounted() {
    this.getAlbumImages();
  },
  methods: {
    getAlbumImages() {
      this.showloader = true;
      this.form
        .get()
        .then((res) => {
          this.album_images = res.data;
          let selectedImages = res.data.filter((fil) => {
            fil.photos.filter((pf) => {
              if (pf.is_selected == "1") {
                this.galleryImages.push(pf);
              }
            });
          });
        })
        .catch((e) => {
          this.showloader = false;
        })
        .finally((f) => {
          this.showloader = false;
        });
    },
    selectImage(data) {
      this.display_save_btn = (data.is_selected === 1 || data.is_selected === "1" )? false : true;
      if (data.is_selected == 1 || data.is_selected == "1") {
        this.delete_pic = true;
      } else {
        this.dialog_open = true;
      }
      this.form.id = data.id;
      this.form.title = data.title;
      this.form.is_selected = 1;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.updatePhoto(1);
      }
    },
    updatePhoto(status) {
      this.form.fireFetch = false;
      let pic_id = this.form.id;
      status === 0 ? (this.form.is_selected = 0) : (this.form.is_selected = 1);
      this.form.post().then((res) => {
        if (res.status == 200) {
          // this.$refs.form.reset();
          this.dialog_open = false;
          this.delete_pic = false;
          this.album_images.filter((fil) => {
            fil.photos.filter((pf) => {
              if (pf.id == pic_id) {
                pf.is_selected = status === 0 ? "O" : "1";
              }
            });
          });
          this.galleryImages = [];

          this.album_images.filter((fil) => {
            fil.photos.filter((pf) => {
              if (pf.is_selected == "1") {
                this.galleryImages.push(pf);
              }
            });
          });
          setTimeout(() => {
            this.delete_pic = false;
            this.refeshKey = this.refeshKey + 1;
          }, 5000);

          // this.getAlbumImages();
        }
      });
    },
  },
};
</script>
<style lang="scss">
.in-gallery {
  border: 5px solid #ff5252;
}

.opacity-background {
  background-color: #000;
  height: 50px;
  opacity: 0.5;

  &:hover {
    background-color: #d0cece;
    cursor: pointer;
    color: #000 !important;
    font-weight: bolder;
  }

  .title {
    &:hover {
      color: #000 !important;
      font-weight: bolder;
    }
  }

  p {
    &:hover {
      color: #000 !important;
      font-weight: bolder;
    }
  }
}

// .v-responsive__content {
//   background-color: #000;
//   height: 50px;
//   opacity: 0.4;
// }
</style>
